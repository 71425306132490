<template>
    <div class="withdraw">
        <div class="navBar">
            <div @click="$router.go(-1)">
                <svg-icon class="leftBtn" iconClass="back"></svg-icon>
            </div>
            <div class="title">游戏提现</div>
            <router-link tag="div" to="/withdrawDetails" class="rightBtn">明细</router-link>
        </div>

        <div class="balanceCard">
            <div class="titleBox">
                <svg-icon class="btn_follow" iconClass="btn_follow" />
                <div>游戏余额（元）</div>
            </div>
            <div class="balance">{{(balance).toFixed(2)}}</div>
        </div>

        <div class="cellItem">
            <div class="cellTitle">提现币类:</div>
            <div class="cellContent amountType">人民币</div>
        </div>
        <div class="cellItem">
            <div class="cellTitle">提现金额:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="withdrawAmount" :border="false"
                :placeholder="`单笔提现金额范围: ${minMoney}-${maxMoney}元`" @input="changeAmount"></van-field>
        </div>
        <div class="cellItem">
            <div class="cellTitle">提现方式:</div>
            <van-radio-group v-model="withdrawTypeIndex" direction="horizontal" class="cellContent radioGroup">
                <div class="radioItem" v-for="item,index in withdrawTypes" :key="index" @click="changeWIthdrawType(index)">
                    <svg-icon class="withdrawIcon" :iconClass="item.payType"></svg-icon>
                    <div>{{formatWithdrawType(item.payType)}}</div>
                    <van-radio checked-color="#fe7f0f" icon-size="16px" :name="index"></van-radio>
                </div>
                <!-- <div class="radioItem">
                    <svg-icon class="withdrawIcon" iconClass="alipay"></svg-icon>
                    <div>支付宝</div>
                    <van-radio checked-color="#fe7f0f" icon-size="16px" name="alipay"></van-radio>
                </div> -->
            </van-radio-group>
        </div>
        <!-- 支付宝 -->
        <div class="cellItem" v-if="withdrawType == 'alipay'">
            <div class="cellTitle">姓名:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="actName" :border="false"
                placeholder="请输入支付宝姓名"></van-field>
        </div>
        <div class="cellItem" v-if="withdrawType == 'alipay'">
            <div class="cellTitle">支付宝:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="act" :border="false"
                placeholder="请输入支付宝账号"></van-field>
        </div>
        <!-- 银行卡 -->
        <div class="cellItem" v-if="withdrawType == 'bankcard'">
            <div class="cellTitle">开户姓名:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="actName" :border="false"
                placeholder="请输入开户姓名"></van-field>
        </div>
        <div class="cellItem" v-if="withdrawType == 'bankcard'">
            <div class="cellTitle">银行卡号:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="act" :border="false" placeholder="请输入银行卡号" @blur="checkBankCard">
            </van-field>
        </div>
        <!-- USDT -->
        <div class="cellItem" v-if="withdrawType == 'usdt'">
            <div class="cellTitle">转账地址:</div>
            <van-field class="cellContent vantInput" :center="true" v-model="act" :border="false" placeholder="请输入转账地址">
            </van-field>
        </div>
        <div class="cellItem" v-if="withdrawType == 'usdt'">
            <div class="cellTitle">温馨提示:</div>
            <div class="cellContent handlingFee">请您务必仔细核对收款地址信息后再做提交，避免给您带来不便。</div>
        </div>
        <div class="cellItem">
            <div class="cellTitle">手续费率:</div>
            <div class="cellContent handlingFee">
                {{`${handlingFee}% 本次提现手续费：${formalitiesAmount}元  实际到账金额：${realAmount}元`}}</div>
        </div>
        <div class="btnBox">
            <div class="withdrawBtn" @click="withdraw">提现</div>
            <div class="tip">提现将在1-2工作日内到账，如未收到，请联系 <router-link tag="span" to="/kefu">在线客服</router-link></div>
        </div>

    </div>
</template>

<script>
    import {
        Field,
        Loading,
        RadioGroup,
        Radio,
        Toast
    } from 'vant';
    import {
        queryWithdrawCfg,
        withdraw,
        checkBankCard,
    } from "@/api/mine";
    import { gameBalance } from "@/api/game";
    export default {
        name: 'Withdraw',
        components: {
            [Field.name]: Field,
            [Loading.name]: Loading,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
        },
        data() {
            return {
                withdrawType: '',
                withdrawTypeIndex: 0, // 提现方式下标
                withdrawAmount: "",
                act: "", // 交易账户
                actName: "", // 交易账户持有人
                bankCode: "", // 银行卡编号
                balance: 0, // 余额
                handlingFee: 0, // 手续费
                formalitiesAmount: 0, //手续费金额
                realAmount: 0, // 实际到账金额
                cid: "", // 渠道ID
                withdrawTypes: [], // 提现类型列表
                minMoney: 0, // 单笔提现最小金额
                maxMoney: 0, // 单笔提现最大金额
                withdrawPlaceholder: "单笔提现金额范围: 1-100元", //提现提示
            }
        },
        created() {
            this.queryBalance();
            this.queryWithdrawCfg();
        },
        methods: {
            // 查询余额
            async queryBalance() {
                let ret = await this.$Api(gameBalance);
                if (ret && ret.code == 200) {
                    this.balance = ret.data.wlBalance || 0;
                }
                // let walletInfo = this.$store.getters.walletInfo;
                // this.balance = ((walletInfo.income * 100 + walletInfo.incomePot * 100)/ 100).toFixed(2);
            },
            // 查询提现配置
            async queryWithdrawCfg() {
                this.$store.commit('app/SET_LOADING', true);
                try {
                    let res = await this.$Api(queryWithdrawCfg);
                    this.$store.commit('app/SET_LOADING', false);
                    if (res && res.code == 200) {
                        this.withdrawTypes = res.data.channels;
                        this.handlingFee = res.data.gameTax;
                        this.changeWIthdrawType(1);
                    }
                } catch (error) {
                    this.$store.commit('app/SET_LOADING', false);
                    Toast('查询失败，请重新进入');
                }

            },
            // 改变提现方式
            changeWIthdrawType(index) {
                this.act = "";
                this.actName = "";
                let typeList = this.withdrawTypes;
                this.withdrawTypeIndex = index;
                // let typeIndex = this.withdrawTypeIndex;
                this.cid = typeList[index].cid;
                this.withdrawType = typeList[index].payType;
                this.minMoney = typeList[index].qpMinMoney / 100;
                this.maxMoney = typeList[index].qpMaxMoney / 100;
            },
            // 计算金额
            changeAmount() {
                let money = this.balance;
                if (this.withdrawAmount > money) {
                    Toast("提现金额大于自身拥有金额");
                    // return;
                }
                // console.log(this.withdrawAmount)
                this.formalitiesAmount = (this.withdrawAmount * this.handlingFee / 100).toFixed(2);
                this.realAmount = (this.withdrawAmount - this.formalitiesAmount).toFixed(2);
            },
            // 提现类型名称
            formatWithdrawType(type) {
                let str = "";
                switch (type) {
                    case "alipay":
                        str = "支付宝";
                        break;
                    case "bankcard":
                        str = "银行卡";
                        break;
                    case "usdt":
                        str = "USDT";
                        break;
                    default:
                        break;
                }
                return str;
            },
            // 校验银行卡
            async checkBankCard() {
                let req = {
                    _input_charset: "utf-8",
                    cardBinCheck: true,
                    cardNo: this.act,
                }
                if (!this.act) return;
                this.$store.commit('app/SET_LOADING', true);
                try {
                    let res = await this.$Api(checkBankCard, req);
                    this.$store.commit('app/SET_LOADING', false);
                    if (res && res.validated && res.bank && res.cardType) {
                        this.bankCode = res.bank;
                    } else {
                        this.bankCode = "";
                        Toast("请输入正确的银行卡号")
                    } 
                } catch (error) {
                    this.$store.commit('app/SET_LOADING', false);
                    Toast('网络错误，请稍后再试');
                }
                
            },
            // 提现
            async withdraw() {
                let userInfo = this.$store.getters.userInfo;
                let money = this.balance;
                
                if (!this.withdrawAmount) {
                    Toast("请输入提现金额");
                    return;
                }
                
                if (this.withdrawAmount > money) {
                    Toast("提现金额大于自身拥有金额");
                    return;
                }
                if (this.withdrawAmount > this.maxMoney) {
                    Toast(`单笔提现金额不大于${this.maxMoney}`);
                    return;
                }
                if (this.withdrawAmount < this.minMoney) {
                    Toast(`单笔提现金额不小于${this.minMoney}元`);
                    return;
                }
                if (!this.actName && this.withdrawType != "usdt") {
                    Toast(this.withdrawType == "alipay" ? "请输入支付宝" : "请输入开户姓名");
                    return;
                }
                if (!this.act) {
                    Toast(this.withdrawType == "alipay" ? "请输入支付宝账号" : "请输入银行卡号");
                    return;
                }
                if (this.withdrawType == "bankcard" && !this.bankCode) {
                    Toast("请输入正确的银行卡号");
                    return;
                }
                
                // if (this.withdrawType == "bankcard" && this.checkBankCard()) {
                //     Toast('请输入正确的银行卡号')
                //     return;
                // }
                
                let req = {
                    name: userInfo.name, // 用户名
                    money: this.withdrawAmount * 100, // 分
                    payType: this.withdrawType, // alipay，bankcard，usdt
                    withdrawType: 1, // 0: 代理提现   1: 金币提现
                    actName: this.actName, // 提现姓名
                    bankCode: this.bankCode, // 银行卡Code
                    act: this.act,// 提现账号
                    cid: this.cid, // 渠道ID
                    devID: this.$store.getters.UID,
                    productType: 1, // 0: 站群  1: 棋牌
                }
                this.$store.commit('app/SET_LOADING', true);
                try {
                    let ret = await this.$Api(withdraw, req);
                    this.$store.commit('app/SET_LOADING', false);
                    if (ret && ret.code === 200) {
                        this.queryBalance();
                        Toast("已提交申请");
                        this.withdrawAmount = "";
                    } else if (ret.tip) {
                        Toast(ret.tip || "提现失败");
                    }
                } catch (error) {
                    Toast('操作失败');
                    this.$store.commit('app/SET_LOADING', false);
                }


            }
        }
    }
</script>

<style lang='scss' scoped>
    .withdraw {
        height: 100%;
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;

        .navBar {
            height: 46px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .leftBtn {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }

            .title {
                font-size: 20px;
            }

            .rightBtn {
                width: 34px;
                height: 24px;
                font-size: 16px;
            }
        }

        .balanceCard {
            height: 84px;
            background: #1e1e1e;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 32px;

            .titleBox {
                display: flex;
                align-items: center;
                margin-top: 12px;
                font-size: 16px;
                color: #ffffff50;

                .btn_follow {
                    width: 7px;
                    height: 21px;
                    margin-right: 15px;
                }
            }

            .balance {
                font-size: 32px;
                margin-left: 22px;
            }
        }

        .cellItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .cellTitle {
                width: 72px;
                margin-right: 2px;
                font-size: 16px;
            }

            .cellContent {
                flex: 2;
            }

            .amountType {
                font-size: 12px;
                color: #939bac;
            }

            .handlingFee {
                font-size: 13px;
                color: #7EA0BE;
            }

            .vantInput {
                height: 34px;
                background: #1e1e1e;

                /deep/ .van-field__control {
                    font-size: 12px;
                    color: #fff;
                }

                /deep/ .van-field__control {
                  font-size: 14px;
                }
            
                ::placeholder {
                  color: #939bac;
                }
            }

            .radioGroup {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 16px;
                // grid-column-gap: 6px;

                .radioItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex: 1;
                    font-size: 16px;

                    .withdrawIcon {
                        width: 39px;
                        height: 39px;
                    }
                }
            }

        }

        .btnBox {
            width: 100%;
            position: fixed;
            left: 0;
            bottom: 56px;
            z-index: 2;

            .withdrawBtn {
                width: 228px;
                height: 44px;
                line-height: 44px;
                border-radius: 44px;
                font-size: 16px;
                text-align: center;
                margin: 0 auto;
                background-image: linear-gradient(to bottom, #ff7f0f, #e38825);
            }

            .tip {
                font-size: 14px;
                margin-top: 20px;
                text-align: center;
                color: #e3e3e3;

                span {
                    color: #fe7f0f;
                }
            }
        }
    }
</style>